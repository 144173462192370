<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="modules"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <modules-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import ModulesMixin from "@/modules/modules/mixins/ModulesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import ModulesForm from "@/modules/modules/components/Form.vue";

@Component({
  components: {
    ActiveIcon,
    ModulesForm,
  },
})
export default class ModulesList extends Mixins(ModulesMixin) {
  onMounted() {
    // const arHeaders: DataTableHeader[] = [];
    // this.addDTHeaders(arHeaders);

    this.index();
  }
}
</script>
